/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.footer{
  text-align: center;
  position: absolute;
  
  margin-top:calc(5% + 60px);
  bottom: 0;
  width: 100% !important;
  height: 50px !important ;
  background: #fff;
}

.dashboard-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  width: calc(33.33% - 20px); /* Adjust width as per your design */
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-text {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .card {
    width: calc(50% - 20px); /* Adjust width for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .card {
    width: 100%; /* Adjust width for even smaller screens */
  }
}

